<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">Slip Pembayaran Kamar Bedah</h3>
    <v-row justify="center">
      <v-col cols="6">
        <v-card color="deep-purple lighten-1" dark>
          <v-card-text>
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form1"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form1[i] = e)"
                  :value="data.form1[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card color="deep-purple lighten-1" dark>
          <v-card-text>
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form2"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form2[i] = e)"
                  :value="data.form2[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <table class="table" border="1">
          <thead>
          <tr class="text-center">
            <th>OBAT/ALKES</th>
            <th style="width: 120px">SAT</th>
            <th style="width: 120px">JLH</th>
            <th style="width: 200px">HARGA</th>
            <th style="width: 50px"></th>
          </tr>
          </thead>
          <tbody v-for="(item,index) in data.form3" :key="index">
          <tr class="text-center">
            <th colspan="4">{{index.toUpperCase()}}</th>
          </tr>
          <tr v-for="(item2,index2) in data.form3[index]" :key="index + '-' + index2">
            <th>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                elevation="12"
                v-model="data.form3[index][index2].p1"
                label=" "
              ></v-text-field>
            </th>
            <th>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                elevation="12"
                v-model="data.form3[index][index2].p2"
                label=" "
              ></v-text-field>
            </th>
            <th>
              <v-text-field
                outlined
                dense
                type="number"
                hide-details="auto"
                elevation="12"
                v-model="data.form3[index][index2].p3"
                label=" "
              ></v-text-field>
            </th>
            <th>
              <v-text-field
                outlined
                dense
                type="number"
                hide-details="auto"
                elevation="12"
                v-model="data.form3[index][index2].p4"
                label=" "
              ></v-text-field>
            </th>
            <th>
              <center>
                <v-btn
                  @click="hapusbaris(index,index2)"
                  fab
                  color="error"
                  dense
                  outlined
                  x-small
                  elevation="2"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </center>
            </th>
          </tr>
          <tr>
            <th colspan="3"></th>
            <th style="max-width: 50px">
              <v-btn class="mt-2 btn-block" @click="tambahbaris(index)" color="primary">+ Baris</v-btn>
            </th>
          </tr>
          </tbody>
        </table>
      </v-col>
      <v-col cols="6">
        <table class="table" border="1">
          <thead>
          <tr class="text-center">
            <th>No</th>
            <th></th>
            <th style="width: 120px">SAT</th>
            <th style="width: 120px">JLH</th>
            <th style="width: 200px">HARGA</th>
            <th style="width: 50px"></th>
          </tr>
          </thead>
          <tbody v-for="(item,index) in data.form4" :key="index">
          <tr class="text-center">
            <th colspan="5">{{index.toUpperCase()}}</th>
          </tr>
          <tr v-for="(item2,index2) in data.form4[index]" :key="index + '-' + index2">
            <th class="text-center">{{index2+1}}</th>
            <th>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                elevation="12"
                v-model="data.form4[index][index2].p1"
                label=" "
              ></v-text-field>
            </th>
            <th>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                elevation="12"
                v-model="data.form4[index][index2].p2"
                label=" "
              ></v-text-field>
            </th>
            <th>
              <v-text-field
                outlined
                dense
                type="number"
                hide-details="auto"
                elevation="12"
                v-model="data.form4[index][index2].p3"
                label=" "
              ></v-text-field>
            </th>
            <th>
              <v-text-field
                outlined
                dense
                type="number"
                hide-details="auto"
                elevation="12"
                v-model="data.form4[index][index2].p4"
                label=" "
              ></v-text-field>
            </th>
            <th>
              <center>
                <v-btn
                  @click="hapusbaris2(index,index2)"
                  fab
                  color="error"
                  dense
                  outlined
                  x-small
                  elevation="2"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </center>
            </th>
          </tr>
          <tr>
            <th colspan="4"></th>
            <th style="max-width: 50px">
              <v-btn class="mt-2 btn-block" @click="tambahbaris2(index)" color="primary">+ Baris</v-btn>
            </th>
          </tr>
          </tbody>
        </table>
        <br>
        <h5>Total : {{formatRupiah(totalharga)}}</h5>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/slip-pembayaran-kamar-bedah'
import Dialog from '@/components/Dialog'
import { baseUrl } from '../../plugins/supports'

export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      ayam: '',
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      data: {
        form1: {},
        form2: {},
        form3: {
          alkes: [
            { p1: 'Adult infusion set/Bloodset', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'ETT No.', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'IV Chateter No.', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Suction Tube No.', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Spuit 1/2/5/10/20/50cc', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Folley Catheter', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Urine Bag', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'NGT No.', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Curaspon', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Mayo/Gudle No.', p2: 'Pcs', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' }
          ],
          'cairan infus': [
            { p1: '5% d/w/ 10 % d/w', p2: 'Kolf', p3: '', p4: '' },
            { p1: 'Ringer Dextrose (RD)', p2: 'Kolf', p3: '', p4: '' },
            { p1: '5% D/O, 225 NaCI (D ½)', p2: 'Kolf', p3: '', p4: '' },
            { p1: '5% D/O, 9 NaCI (2A)', p2: 'Kolf', p3: '', p4: '' },
            { p1: 'Pan Amin G/ Ka En 2 B', p2: 'Kolf', p3: '', p4: '' },
            { p1: 'NaCi 0,9 %', p2: 'Kolf', p3: '', p4: '' },
            { p1: 'Ringer Lactate', p2: 'Kolf', p3: '', p4: '' },
            { p1: 'Aqua pro injection', p2: 'Kolf', p3: '', p4: '' },
            { p1: 'Assering', p2: 'Kolf', p3: '', p4: '' },
            { p1: 'Gellofusin / gellafusal', p2: 'Kolf', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' }
          ],
          'obat anastesi': [
            { p1: 'Adrenalin :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Atropin sulfat inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Clonidine inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Midazolam 15 mg inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Ephedrin inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Ketamin inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Lidocain inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Bupivacaine inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Methylergometrine inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Lidocain + Epinefrin Inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Ketoprofen supp/Inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Nestigmin inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Propofol inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Oxytocin inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Tramadol inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Atracrium besylate inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Vitamin C inj:', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Roconorium bromide inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Dexmedetomidine inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Vecuronium bromide inj :', p2: 'Vial', p3: '', p4: '' },
            { p1: 'Aminophylin inj:', p2: 'Vial', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' }
          ],
          'gas anastesi': [
            { p1: 'N20', p2: '', p3: '', p4: '' },
            { p1: 'O2', p2: '', p3: '', p4: '' },
            { p1: 'Terrel', p2: 'cc', p3: '', p4: '' },
            { p1: 'Sojoum', p2: 'cc', p3: '', p4: '' }
          ]
        },
        form4: {
          antibiotika: [
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' }
          ],
          tindakan: [
            { p1: 'Assering', p2: 'Kolf', p3: '', p4: '' },
            { p1: 'Gellofusin / gellafusal', p2: 'Kolf', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' }
          ],
          'benang roll': [
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' }
          ],
          'benang rcs': [
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' }
          ],
          'lain-lain': [
            { p1: 'Abd Sponge / Tampon gulung', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Abd Tampon', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Elastic bandage', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Gauze 10 x 10 cm ( Kasa)', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Polygip / Gipsona', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Glaves steril (-Gamex No.)', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Glaves steril (-E. Care No.)', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Larutan Betadine', p2: 'Cc', p3: '', p4: '' },
            { p1: 'Masker', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Opsite', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Bisturi / Pisau aesculap :', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Bisturi / Pisau aesculap :', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Framycetin Sulfate :', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Softband', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Spinal needle /spinocan', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Wing needle', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Underpad', p2: 'Pcs', p3: '', p4: '' },
            { p1: 'Hypafix', p2: 'Pcs', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' },
            { p1: '', p2: '', p3: '', p4: '' }
          ]
        },
        total: 0
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  computed: {
    totalharga: function () {
      var hmm = this

      let sumP3 = 0
      let sumP4 = 0

      for (const category in hmm.data.form3) {
        for (const item of hmm.data.form3[category]) {
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty('p4') && !isNaN(parseFloat(item.p4))) {
            // Add the value to the sum
            sumP4 += parseFloat(item.p4)
          }
        }
      }

      for (const category in hmm.data.form4) {
        for (const item of hmm.data.form4[category]) {
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty('p4') && !isNaN(parseFloat(item.p4))) {
            // Add the value to the sum
            sumP4 += parseFloat(item.p4)
          }
        }
      }

      if (isNaN(sumP3)) {
        sumP3 = 0
      } else {
        sumP3 = parseInt(sumP3)
      }
      if (isNaN(sumP4)) {
        sumP4 = 0
      } else {
        sumP4 = parseInt(sumP4)
      }
      return (sumP3 + sumP4).toString()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
    }
  },
  methods: {
    formatRupiah (angka) {
      let formatted = angka.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

      // Menambahkan simbol mata uang
      formatted = `Rp ${formatted}`

      return formatted
    },
    tambahbaris (key) {
      this.data.form3[key].push({
        p1: '',
        p2: '',
        p3: '',
        p4: ''
      })
    },
    hapusbaris (key, index) {
      this.data.form3[key].splice(index, 1)
    },
    tambahbaris2 (key) {
      this.data.form4[key].push({
        p1: '',
        p2: '',
        p3: '',
        p4: ''
      })
    },
    hapusbaris2 (key, index) {
      this.data.form4[key].splice(index, 1)
    },
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Populasi Khusus'
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
          } else {
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Populasi Khusus'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>

.wrap {
  width: 100%;
  height: 690px;
  padding: 0;
  overflow: hidden;
}
.frame {
  width: 1280px;
  height: 926px;
  border: 0;
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.float-group {
  position: fixed;
  bottom: 50%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.framclass {
  width: 1280px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.Div1 {
  border: 1px solid #ddd;
  width: 24%;
  background-color: white;
  float: left;
  border: 2px solid #c00;
  margin-right: 5px;
  min-height: 50px;
  position: fixed;
}
</style>
