export default {
  form1: {
    p1: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 12
    },
    p2: {
      label: 'Tanggal Lahir',
      widget: 'wdate',
      data: null,
      col: 6
    },
    p3: {
      widget: 'wtext',
      label: 'Ruangan rawat / kamar',
      data: null,
      col: 6
    },
    p4: {
      widget: 'wradio',
      data: [
        { text: 'Canggih', value: 'Canggih' },
        { text: 'Kecil', value: 'Kecil' },
        { text: 'Sedang', value: 'Sedang' },
        { text: 'Besar', value: 'Besar' },
        { text: 'Khusus', value: 'Khusus' }
      ],
      col: 6
    },
    p5: {
      widget: 'wtext',
      label: 'Nama Operasi',
      data: null,
      col: 6
    },
    p6: {
      widget: 'wtext',
      label: 'Lama Operasi',
      data: null,
      col: 6,
      property: { attrs: { prefix: 'Jam' } }
    },
    p7: {
      widget: 'wtext',
      label: 'Lama Operasi',
      data: null,
      col: 6,
      property: { attrs: { prefix: 's/d' } }
    }
  },
  form2: {
    p1: {
      label: 'Nomor Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 6
    },
    p2: {
      label: 'Tanggal Pembedahan',
      widget: 'wdatenormal',
      data: null,
      col: 6
    },
    p3: {
      label: 'Dokter Bedah',
      widget: 'wtext',
      data: null,
      col: 6
    },
    p4: {
      label: 'Dokter Anastesi',
      widget: 'wtext',
      data: null,
      col: 6
    },
    p5: {
      label: 'Penata Anastesi',
      widget: 'wtext',
      data: null,
      col: 6
    },
    p6: {
      label: 'Asisten',
      widget: 'wtext',
      data: null,
      col: 6
    },
    p7: {
      label: 'Kamar Bedah',
      widget: 'wtext',
      data: null,
      col: 6
    }
  }
}
